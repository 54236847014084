import { Link } from "gatsby"
import React from "react"

const PageLink = ({ children, url, disabled }) => {
  return (
    <Link
      className={
        "text-black  hover:text-createneongreen transition duration-500 underline cursor-pointer" +
        (disabled ? " pointer-events-none" : "")
      }
      to={url}
    >
      {children}
    </Link>
  )
}

export default PageLink
