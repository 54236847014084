import React from "react"

const StyledHeader = ({
  children,
  backgroundText,
  type,
  Tag,
  noWrap,
  split,
}) => {
  return (
    <div className="relative -z-10">
      <Tag className="absolute z-40 uppercase bottom-0 text-2xl">
        {children}
      </Tag>
      <div className={noWrap ? "lg:whitespace-nowrap -z-50" : ""}>
        <span
          style={split ? { wordSpacing: "-0.3em" } : {}}
          className="pointer-events-none select-none font-cako text-8xl text-createneongreen break-words leading-[0.8] lowercase"
        >
          {backgroundText}
        </span>
      </div>
    </div>
  )
}

export default StyledHeader
