import React, { useState } from "react"
import Layout from "../components/layout"

import { Helmet } from "react-helmet"
import StyledHeader from "../components/styledHeader"

import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"

import { FaWhatsapp } from "@react-icons/all-files/fa/FaWhatsapp"

import { FaPinterest } from "@react-icons/all-files/fa/FaPinterest"

import { ImPhone } from "@react-icons/all-files/im/ImPhone"

import { BsFillEnvelopeFill } from "@react-icons/all-files/bs/BsFillEnvelopeFill"
import PageLink from "../components/pageLink"

import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => {
  console.log(data)
  const [value, setValue] = useState({})

  const [message, setMessage] = useState(``)

  const [isLoading, setIsLoading] = useState(false)

  function handleChange(e) {
    e.preventDefault()
    value[e.target.id] = e.target.value
    console.log(e)
    setMessage(``)

    setValue({ ...value })
  }

  function onFormSubmit(e) {
    e.preventDefault()
    setIsLoading(true)
    setMessage("Sending...")

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LcBAUIhAAAAAMaONBQ3oizFD0MSOLf9y4gC7Ptf", {
          action: "submit",
        })
        .then(function (token) {
          const body = {
            token: token,
            ...value,
          }
          JSON.stringify(body)
          fetch("/.netlify/functions/form", {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(body), // body data type must match "Content-Type" header
          })
            .then(res => res.json())
            .then(json => {
              if (json.item === "success") {
                setValue({})
              }
              setMessage(json.message)
              //setMessageItem(json.item)
              setIsLoading(false)
            })
            .catch(err => {
              console.log(err)
              setIsLoading(false)
              setMessage("Error sending message. Please try again.")
            })
        })
        .catch(err => {
          console.log(err)

          setMessage("Captcha error. Please try again.")
          //setMessageItem("Fail")
        })
    })
  }

  return (
    <>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js?render=6LcBAUIhAAAAAMaONBQ3oizFD0MSOLf9y4gC7Ptf"></script>
      </Helmet>
      <Seo post={data.wpPage} />
      <Layout>
        <div className="mx-auto max-w-screen-2xl lg:mt-20 pt-10 sm:pt-20">
          <div className="grid lg:grid-cols-4">
            <div className="col-span-3">
              <div className="lg:border-black lg:border-2 m-5 lg:mb-0">
                <div className="lg:-mt-7 lg:pl-6">
                  <StyledHeader Tag="h1" backgroundText="let's chat">
                    Contact Us
                  </StyledHeader>
                </div>
              </div>
              <form className="m-5 lg:mt-0 grid grid-cols-1 gap-6 lg:gap-0">
                <div className="block lg:grid lg:grid-cols-4">
                  <div className="uppercase flex items-center lg:border-black lg:border-l-2 lg:border-b-2 lg:p-2">
                    Name
                  </div>
                  <input
                    type="text"
                    className="w-full bg-transparent text-black lg:col-span-3 border-2 border-black text-2xl p-1 lg:border-t-0"
                    value={value[`name`] || ``}
                    onChange={handleChange}
                    id="name"
                  ></input>
                </div>
                <div className="block lg:grid lg:grid-cols-4">
                  <div className="uppercase flex items-center lg:border-black lg:border-l-2 lg:border-b-2 lg:p-2">
                    Email
                  </div>
                  <input
                    type="email"
                    className="w-full bg-transparent text-black lg:col-span-3 border-2 border-black text-2xl p-1 lg:border-t-0"
                    value={value[`email`] || ``}
                    onChange={handleChange}
                    id="email"
                  ></input>
                </div>
                <div className="block lg:grid lg:grid-cols-4">
                  <div className="uppercase flex lg:border-black lg:border-l-2 lg:border-b-2 lg:p-2">
                    Message
                  </div>
                  <textarea
                    type="textarea"
                    className="w-full bg-transparent text-black lg:col-span-3 border-2 border-black text-2xl p-1 lg:border-t-0 h-52"
                    value={value[`message`] || ``}
                    onChange={handleChange}
                    id="message"
                  ></textarea>
                </div>
              </form>
              <div className="relative h-0">{message}</div>
              <div className="relative h-0 text-right pr-5">
                <button
                  className="tracking-normal"
                  onClick={e => onFormSubmit(e)}
                  data-action="submit"
                >
                  <PageLink disabled={isLoading}>submit</PageLink>
                </button>
              </div>
            </div>
            <div className="hidden lg:flex items-end">
              <div className="mb-2">
                <div className="uppercase text-xl pb-4">Reach out</div>
                <div className="text-black">
                  <div className="mb-2">
                    <a
                      href="https://www.instagram.com/createdesign.studio"
                      className="text-black flex items-center gap-2"
                    >
                      <div className="text-4xl">
                        <FaInstagram />
                      </div>
                      <div>createdesign.studio</div>
                    </a>
                  </div>
                  <div className="mb-2">
                    <a
                      href="https://www.pinterest.nz/createdesign_studio/"
                      className="text-black flex items-center gap-2"
                    >
                      <div className="text-4xl">
                        <FaPinterest />
                      </div>
                      <div>createdesign_studio</div>
                    </a>
                  </div>
                  <div className="mb-2">
                    <a
                      href="tel:+642108215063"
                      className="text-black flex items-center gap-2"
                    >
                      <div className="text-4xl">
                        <ImPhone />
                      </div>
                      <div>(+64) 210 821 5063</div>
                    </a>
                  </div>
                  <div className="mb-2">
                    <a
                      href="https://wa.me/+642108215063"
                      className="text-black flex items-center gap-2"
                    >
                      <div className="text-4xl">
                        <FaWhatsapp />
                      </div>
                      <div>(+64) 210 821 5063</div>
                    </a>
                  </div>
                  <div className="mb-2">
                    <a
                      href="mailto:hello@createdesign.studio"
                      className="text-black flex items-center gap-2"
                    >
                      <div className="text-4xl">
                        <BsFillEnvelopeFill />
                      </div>
                      <div>hello@createdesign.studio</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query GET_PAGE2 {
    wpPage(id: { eq: "cG9zdDo3OTIx" }) {
      nodeType
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
